import type { ShouldRevalidateFunction } from "@remix-run/react";

/*
 Export `shouldRevalidate` in the route files if there is no other revalidation logic.
 Its required to prevent fetch request while a drawer on the page opens trough a query in the URL.
*/

export const shouldRevalidate: ShouldRevalidateFunction = ({
  nextUrl,
  currentUrl,
  defaultShouldRevalidate,
}) => {
  return shouldDrawerRevalidatePage(
    nextUrl,
    currentUrl,
    defaultShouldRevalidate,
  );
};

// Use the shouldDrawerRevalidatePage check as a part of other `shouldRevalidate` functions if needed
export const shouldDrawerRevalidatePage = (
  nextUrl: URL,
  currentUrl: URL,
  defaultShouldRevalidate: boolean,
) => {
  return defaultShouldRevalidate;
};
